import { useSnackbar } from "notistack"

/** Use for imperative implementation of toasts */
export function useToast({
  message,
  action,
  autoHideDuration,
}: {
  message?: React.ReactNode
  action?: React.ReactNode
  autoHideDuration?: number
}) {
  const snackbar = useSnackbar()

  function show(
    messageOverride?: React.ReactNode,
    options?: { action: React.ReactNode }
  ) {
    snackbar.enqueueSnackbar(messageOverride ?? message, {
      autoHideDuration,
      action: options?.action ?? action,
    })
  }

  return {
    show,
    close: snackbar.closeSnackbar,
  }
}
