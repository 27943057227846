import { MButton, TMButtonProps } from "src/ui/Button/MButton"

export function ToastActionButton({
  text,
  onClick,
  ...props
}: {
  text: string
  onClick: () => void
} & TMButtonProps) {
  return (
    <MButton variant="secondary" size="small" onClick={onClick} {...props}>
      {text}
    </MButton>
  )
}
