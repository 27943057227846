import styled from "styled-components"

import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core"

import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { BodyMixin } from "src/ui/MText"

import { TMemberAccess } from "./memberAccessTypes"

interface IMemberAccessLevel {
  id: TMemberAccess
  label: string
  description: string
}

export function MemberAccessPicker({
  access,
  onChange,
}: {
  access: TMemberAccess | undefined
  onChange: (access: TMemberAccess) => void
}) {
  const { t, langKeys } = useTranslate()

  const { orgAccessLogic } = useOrganization()

  const accessLevelsAll: IMemberAccessLevel[] = [
    {
      id: "org-level-access",
      label: t(langKeys.organization_access_title),
      description: t(langKeys.organizations_access_all_homes_description),
    },
    {
      id: "homegroup-level-access",
      label: t(langKeys.organizations_homegroup_access_title),
      description: t(
        langKeys.organizations_homegroup_access_all_homes_description
      ),
    },
    {
      id: "home-level-access",
      label: t(langKeys.home_access_title),
      description: t(langKeys.home_access_single_home_description),
    },
  ]
  // Prevent non-org admins from selecting any organization level invites:
  const accessLevels = accessLevelsAll.filter((item) =>
    item.id === "org-level-access" && !orgAccessLogic.hasAdminAccess
      ? false
      : true
  )

  function renderValue() {
    if (!access) return null
    return accessLevels.find((o) => o.id === access)?.label ?? null
  }

  const menuItems = accessLevels.map((level) => {
    return (
      <MenuItem key={level.id} value={level.id}>
        <ListItemText primary={level.label} secondary={level.description} />
      </MenuItem>
    )
  })

  return (
    <FormControl>
      <InputLabel htmlFor="access">{t(langKeys.access_title)}</InputLabel>
      <StyledSelect
        id="access-level"
        label={t(langKeys.access_title)}
        value={access ?? ""}
        onChange={(e) => onChange(e.target.value as TMemberAccess)}
        renderValue={() => renderValue()}
      >
        {menuItems}
      </StyledSelect>
    </FormControl>
  )
}

const StyledSelect = styled(Select)`
  &.Mui-disabled::before,
  &::before {
    border-bottom: none;
    border-bottom-style: none;
  }
  ${BodyMixin};
`
