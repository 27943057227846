import { Children } from "react"
import styled from "styled-components"

export const FullscreenActionBar = styled.div<{
  children: React.ReactNode
}>`
  display: flex;
  justify-content: ${({ children }) =>
    Children.count(children) > 1 ? "space-between" : "flex-end"};
`
